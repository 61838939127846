@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");

    .light-theme {
        .offer-card.active-card,
        .offer-card:hover {
            border-color: mat.get-color-from-palette($primary-palette, 100) !important;
            color: mat.get-color-from-palette($primary-palette, 500);
            h1 {
                color: #000;
            }
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}
