@use "./themes/_offer-card-theme" as offer-card;
@use "@angular/material" as mat;
@use "sass:map";

@use "./themes/mat-form-field.theme" as custom-mat-form-field;
@use "./themes/button-theme" as custom-mat-button;
@include mat.core();

html,
body {
    height: 100%;
    overflow-x: hidden;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* ---------------- Custom Palette ---------------- */
/* For use in src/lib/core/theming/_palette.scss */
$primary: (
    50: #e1e6f1,
    100: #b3c2dc,
    200: #8199c5,
    300: #4f70ad,
    400: #29519c,
    500: #03328a,
    600: #032d82,
    700: #022677,
    800: #021f6d,
    900: #01135a,
    A100: #8b99ff,
    A200: #586dff,
    A400: #2540ff,
    A700: #0c29ff,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff)
);

$accent: (
    50: #fefdf7,
    100: #fdfbeb,
    200: #fcf8de,
    300: #fbf5d0,
    400: #faf3c6,
    500: #f9f1bc,
    600: #f8efb6,
    700: #f7edad,
    800: #f6eba5,
    900: #f5e797,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #000000,
        600: #000000,
        700: #000000,
        800: #000000,
        900: #000000,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000)
);

$warn: (
    50: #ffe0eb,
    100: #ffb3cd,
    200: #ff80ac,
    300: #ff4d8b,
    400: #ff2672,
    500: #ff0059,
    600: #ff0051,
    700: #ff0048,
    800: #ff003e,
    900: #ff002e,
    A100: #ffffff,
    A200: #fff2f4,
    A400: #ffbfc7,
    A700: #ffa6b1,
    contrast: (50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000)
);

/* ---------------- Custom Font  ---------------- */
/*
@font-face {
    font-family: "Nunito";
    font-weight: 100;
    src: url("assets/fonts/Nunito-Thin.ttf") format("truetype");
}*/
@font-face {
    font-family: "Nunito";
    font-weight: 200;
    src: url("assets/fonts/Nunito-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    font-weight: 300;
    src: url("assets/fonts/Nunito-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    font-weight: 500;
    src: url("assets/fonts/Nunito-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    font-weight: 600;
    src: url("assets/fonts/Nunito-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    font-weight: 700;
    src: url("assets/fonts/Nunito-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    font-weight: 800;
    src: url("assets/fonts/Nunito-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    font-weight: 900;
    src: url("assets/fonts/Nunito-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Cherry";
    font-weight: 200;
    src: url("assets/fonts/CherryBombOne-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Urbanist";
    font-weight: 200;
    src: url("assets/fonts/Urbanist-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Urbanist";
    font-weight: 300;
    src: url("assets/fonts/Urbanist-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Urbanist";
    font-weight: 500;
    src: url("assets/fonts/Urbanist-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Urbanist";
    font-weight: 600;
    src: url("assets/fonts/Urbanist-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Urbanist";
    font-weight: 700;
    src: url("assets/fonts/Urbanist-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Urbanist";
    font-weight: 800;
    src: url("assets/fonts/Urbanist-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Urbanist";
    font-weight: 900;
    src: url("assets/fonts/Urbanist-Black.ttf") format("truetype");
}

$custom-typography: mat.define-typography-config($font-family: "Urbanist"
        /*"Nunito"*/
    );
@include mat.typography-hierarchy($custom-typography);

/* ---------------- Custom Theme ---------------- */
// Palette de couleurs pour le thème clair
$light-primary: mat.define-palette($primary);
$light-accent: mat.define-palette($accent);
$light-warn: mat.define-palette($warn);
$light-theme: mat.define-light-theme((color: (primary: $light-primary,
                accent: $light-accent,
                warn: $light-warn ),
            typography: $custom-typography,
            density: -1 // Default 0
        ));

// Palette de couleurs pour le thème sombre
$dark-primary: mat.define-palette($primary);
$dark-accent: mat.define-palette($accent);
$dark-warn: mat.define-palette($warn);
$dark-theme: mat.define-dark-theme($dark-accent, $dark-primary, $dark-warn);

// Fonction pour appliquer le thème en fonction du mode sombre ou clair
.light-theme {
    @include mat.core-theme($light-theme);
    @include mat.all-component-themes($light-theme);
}

.dark-theme {
    @include mat.core-theme($dark-theme);
    @include mat.all-component-themes($dark-theme);
}

// Get Color
$color-config: mat.get-color-config($light-theme);
$primary-palette: map.get($color-config, "primary");
$warn-palette: map.get($color-config, "warn");
$primary: mat.get-color-from-palette($primary-palette, 500);

.mat-primary-color {
    color: mat.get-color-from-palette($primary-palette, 500);
}

.mat-primary-background {
    background-color: mat.get-color-from-palette($primary-palette, 500) !important;
}

.mat-warn-background {
    background-color: mat.get-color-from-palette($warn-palette, 500) !important;
}

.mat-pastel-primary-background {
    background-color: rgba(mat.get-color-from-palette($primary-palette, 500), 0.1);
}

.mat-primary-border {
    border-color: mat.get-color-from-palette($primary-palette, 500) !important;
}

.mat-warn-color {
    color: mat.get-color-from-palette($warn-palette, 500);
}

.mat-pastel-warn-background {
    background-color: rgba(mat.get-color-from-palette($warn-palette, 500), 0.1);
}

.mat-warn-border {
    border-color: mat.get-color-from-palette($warn-palette, 500) !important;
}

@include offer-card.theme($light-theme);
@include custom-mat-form-field.theme($light-theme);
@include custom-mat-button.theme($light-theme);

/* ---------------- GLOBAL Theme ---------------- */

.mat-typography h2 {
    font-size: 32px;
    font-weight: 700;
}

.mat-typography h3 {
    font-size: 24px;
    font-weight: 700;
}

.mat-typography h4.formly-text {
    font-weight: 600;
}

.w-100 {
    width: 100%;
}

.w-75 {
    width: 75%;
}

.w-50 {
    width: 50%;
}

.w-25 {
    width: 25%;
}

.pl-0 {
    padding: 0;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.text-justify {
    text-align: justify;
}

.svg-icon-prefix {
    margin: 0 10px !important;
}

.card-border {
    background-color: #fff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding: 24px;

    .card-border-header {
        font-size: 20px;
        font-weight: 700;
    }
}

// Expand Panel
.mat-expansion-panel-body {
    padding: 0px !important;
}

.light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled="true"]),
.light-theme .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled="true"]),
.light-theme .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled="true"]) {
    background-color: #03328a;

    >* {
        color: #fff;
    }
}

// Sidenav Responsive
.mat-drawer-backdrop.mat-drawer-shown {
    visibility: hidden !important;
}

.btnMobile {
    mat-icon {
        margin: 0px !important;
    }
}

.inputMobile {
    width: 100%;
}

.cdk-overlay-pane {
    max-width: 100vw !important;
}

// Toggle Slider
.light-theme .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: #5d9c59;
    --mdc-switch-selected-handle-color: #5d9c59;
    --mdc-switch-selected-hover-state-layer-color: #5d9c59;
    --mdc-switch-selected-pressed-state-layer-color: #5d9c59;
    --mdc-switch-selected-focus-handle-color: #457341;
    --mdc-switch-selected-hover-handle-color: #457341;
    --mdc-switch-selected-pressed-handle-color: #457341;
    --mdc-switch-selected-focus-track-color: #5d9c59;
    --mdc-switch-selected-hover-track-color: #5d9c59;
    --mdc-switch-selected-pressed-track-color: #5d9c59;
    --mdc-switch-selected-track-color: #5d9c59;
}

// Mat Tab Group
.mat-mdc-dialog-content {
    // max-height: 75vh !important;
    max-height: fit-content !important;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (min-width: 1280px) {

    // Mat Tab Group
    .mat-mdc-tab-header {
        width: fit-content !important;
    }
}