@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");

    .light-theme {
        .mat-mdc-button-base {
            padding: 22px 24px;
            border-radius: 8px;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}