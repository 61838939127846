@use "sass:map";
@use "@angular/material" as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, "primary");

    .light-theme {

        // Change Background ( Color / Radius ... )
        .mdc-text-field {
            background-color: #fafafa;
            border-radius: 16px;
        }

        // Remove Border Bottom
        .mdc-line-ripple {
            display: none;
        }

        .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
            color: rgba(0, 0, 0, 1);
        }

        // Input Hover
        .mdc-text-field--focused:not(.mdc-text-field--disabled) {
            background-color: rgba(mat.get-color-from-palette($primary-palette, 500), 0.05);

            .mdc-floating-label {
                color: mat.get-color-from-palette($primary-palette, 500);
            }
        }

        // Input disabled
        .mat-form-field-disabled {
            cursor: not-allowed;
        }
    }


}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }
}